import * as yup from 'yup'
import { parseISO } from 'date-fns/parseISO'
import { differenceInDays } from 'date-fns/differenceInDays'

import type { LOCATION_SEARCH_TYPE, TravelerState } from '@/types'
import type { ChildrenAges } from '@pcln/traveler-selection'
import { FLY, DRIVE } from '../constants'
import type { HOTEL_FORM_STATE_TYPE } from '../types'

const MAXIMUM_BUNDLE_DATE_LENGTH = 330

export const LOCATION_REQUIRED =
  'Please enter your City, Airport, Point of Interest, Hotel Name or U.S. Zip Code'
export const PKG_LOCATION_REQUIRED = 'Please enter a city or airport'
const PKG_LOCATION_INCORRECT =
  'Please select a city or airport to add a flight to your hotel'
export const INVALID_DATE = 'Please select valid dates'
const DISALLOW_INFANT_INTL_FLIGHT =
  'Sorry, we do not offer lap infant seating on international flights'
export const INFANT_CAPACITY_LIMIT = 'Only one infant per adult is allowed'
export const CHILD_AGE_REQUIRED =
  "Please enter children's age to get the best price for your group"
const MAXIMUM_BUNDLE_GUEST_COUNT = 8
export const BUNDLE_MAX_CAPACITY_ERROR =
  'Bundles accommodate up to 8 people. You may need an additional bundle for your group'
const BUNDLE_MAX_DATE_ERROR = `Sorry! We only support bundles up to ${MAXIMUM_BUNDLE_DATE_LENGTH} days in advance. Please choose a date within this window.`

const intlLocation = (locationObject: LOCATION_SEARCH_TYPE | null) =>
  locationObject &&
  typeof locationObject.countryCode === 'string' &&
  locationObject.countryCode.toUpperCase() !== 'US'

const isInfantFound = (childrenAges: ChildrenAges) => {
  return Object.values(childrenAges).some(age => parseInt(age, 10) === 0)
}

const isLocationWithInfant = (
  locationObject: LOCATION_SEARCH_TYPE | null,
  childrenAges: ChildrenAges
) => {
  return intlLocation(locationObject) && isInfantFound(childrenAges)
}

const numOfValidAges = (childrenAges: ChildrenAges): number[] => {
  return Object.values(childrenAges)
    .filter((age): age is string => typeof age === 'string')
    .filter(age => {
      const num = parseInt(age, 10)
      return !Number.isNaN(num) && num >= 0
    })
    .map(age => parseInt(age, 10))
}

const isInfantOnInternationalTravel = (
  location: LOCATION_SEARCH_TYPE | null,
  childrenAges: ChildrenAges
) => {
  return isLocationWithInfant(location, childrenAges)
}

function isChildrenAgeNotProvided(
  childrenCount: number,
  childrenAges: ChildrenAges
) {
  const validAgesCount = numOfValidAges(childrenAges).length
  return childrenCount > 0 && childrenCount !== validAgesCount
}

export const isInfantLimitReachedPackages = (
  adultCount: number,
  childrenAges: ChildrenAges,
  tripType: string
) => {
  const lapInfants = Object.values(childrenAges).filter(age => {
    const numAge = parseInt(age, 10)
    return !Number.isNaN(numAge) && numAge === 0
  })
  const infantCount = lapInfants.length
  return tripType.includes(FLY) && infantCount > adultCount
}

function handleInfantChildrenTravel(
  this: yup.TestContext,
  formState: HOTEL_FORM_STATE_TYPE
) {
  const { childrenAges, adults, children } = this.parent
  const { startLocation, endLocation, tripType } = formState
  if (
    tripType.includes(FLY) &&
    endLocation &&
    startLocation &&
    (isInfantOnInternationalTravel(endLocation, childrenAges) ||
      isInfantOnInternationalTravel(startLocation, childrenAges))
  ) {
    return this.createError({
      message: DISALLOW_INFANT_INTL_FLIGHT,
      path: this.path
    })
  }

  if (isInfantLimitReachedPackages(adults, childrenAges, tripType)) {
    return this.createError({
      message: INFANT_CAPACITY_LIMIT,
      path: this.path
    })
  }

  if (isChildrenAgeNotProvided(children, childrenAges)) {
    return this.createError({
      message: CHILD_AGE_REQUIRED,
      path: this.path
    })
  }

  return true
}

function handleRoomCapacity(
  this: yup.TestContext,
  formState: HOTEL_FORM_STATE_TYPE
) {
  const { adults, children } = this.parent as TravelerState
  const { tripType } = formState
  const travelerCount = adults + children
  if (
    MAXIMUM_BUNDLE_GUEST_COUNT < travelerCount &&
    (tripType.includes(FLY) || tripType.includes(DRIVE))
  ) {
    return this.createError({
      message: BUNDLE_MAX_CAPACITY_ERROR,
      path: this.path
    })
  }

  return true
}

function handlePackageLocation(this: yup.TestContext) {
  const { endLocation } = this.parent as HOTEL_FORM_STATE_TYPE

  if (!endLocation) {
    return this.createError({
      message: PKG_LOCATION_REQUIRED,
      path: this.path
    })
  }
  if (
    endLocation.type !== 'CITY' &&
    endLocation.type !== 'AIRPORT' &&
    endLocation.type !== 'GEO' &&
    endLocation.type !== 'POI'
  ) {
    return this.createError({
      message: PKG_LOCATION_INCORRECT,
      path: this.path
    })
  }
  return true
}

function handleDateCheck(this: yup.TestContext) {
  const { tripType } = this.parent as HOTEL_FORM_STATE_TYPE

  const dateValue = this.parent[this.path]
  const dateDiff = differenceInDays(parseISO(dateValue), Date.now())

  if (
    dateDiff > MAXIMUM_BUNDLE_DATE_LENGTH &&
    (tripType.includes(FLY) || tripType.includes(DRIVE))
  ) {
    return this.createError({
      message: BUNDLE_MAX_DATE_ERROR,
      path: this.path
    })
  }

  return true
}

export const searchFormSchema = yup.object().shape({
  tripType: yup.string().required(),
  partialStay: yup.bool(),
  startLocation: yup
    .object()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType.includes(FLY),
      then: schema => schema.nullable().required(PKG_LOCATION_REQUIRED)
    }),
  endLocation: yup
    .object()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType.includes(FLY),
      then: schema =>
        schema.nullable().test({
          name: 'endLocationPkg',
          test: handlePackageLocation
        }),
      otherwise: schema => schema.nullable().required(LOCATION_REQUIRED)
    }),
  startDate: yup.string().required(INVALID_DATE).test(handleDateCheck),
  endDate: yup.string().required(INVALID_DATE).test(handleDateCheck),
  travelers: yup.object().shape({
    children: yup.number().test({
      name: 'children',
      test() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return handleInfantChildrenTravel.call(this, this.options.from[1].value)
      }
    }),
    rooms: yup.number().test({
      name: 'rooms',
      test() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return handleRoomCapacity.call(this, this.options.from[1].value)
      }
    })
  })
})
