import useDetectBrowser from './useDetectBrowser'

export default function useIsIPad() {
  const isBrowser = useDetectBrowser()

  if (!isBrowser) {
    return false
  }

  return /iPad|Macintosh/.test(navigator.userAgent) && 'ontouchend' in document
}
