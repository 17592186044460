import React from 'react'
import styled from 'styled-components'
import { Flex, Label, Radio } from 'pcln-design-system'

const StyledLabel = styled(Label)`
  cursor: pointer;
`

type FormTypeRadios = {
  formType: string
  onChange: (selectedFormName: string) => void
}

export default function FormTypeRadios({ formType, onChange }: FormTypeRadios) {
  return (
    <Flex mb={2}>
      <Flex mr={3} alignItems="center">
        <StyledLabel fontSize={0} fontWeight={500} nowrap>
          <Radio
            checked={formType === 'single'}
            onChange={() => {
              onChange('single')
            }}
          />
          Single Hotel
        </StyledLabel>
      </Flex>

      <Flex mr={3} alignItems="center">
        <StyledLabel fontSize={0} fontWeight={500} nowrap>
          <Radio
            checked={formType === 'multi'}
            onChange={() => {
              onChange('multi')
            }}
          />
          Multi Hotel
        </StyledLabel>
      </Flex>
    </Flex>
  )
}
