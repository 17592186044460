export const STAY = 'STAY'
export const FLY = 'FLY'
export const DRIVE = 'DRIVE'

export const PromoDeal = {
  EXPRESS_DEAL: 'EXPRESS_DEAL',
  COLLECTIONS: 'COLLECTIONS',
  VIP: 'VIP',
  NHA: 'NHA'
} as const

export const CALENDAR_POPOVER_SELECTOR =
  '[aria-label="Calendar navigation tooltip"]'

export const PRIMARY_HEADING_FALLBACK = 'Searching for a place to stay?'

export const VP_DISCLAIMER =
  'You may only book a package for up to 8 travelers.'

export const GEO = 'GEO'
