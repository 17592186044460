import React from 'react'
import styled from 'styled-components'
import { FilterChip, Flex, Text } from 'pcln-design-system'
import {
  fireFilterBarGA4Event,
  fireFilterBarChipGA4Event
} from '@/components/TypeAhead/FilterBar/ga4'
import type { FilterCategoryHotel, SelectedTopFilters } from '../types'

const Wrapper = styled(Flex)`
  gap: 8px;
  position: relative;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

export default function SelectedTopFilterBar({
  selectedTopFilters,
  setSelectedTopFilters
}: {
  selectedTopFilters: SelectedTopFilters
  setSelectedTopFilters: React.Dispatch<
    React.SetStateAction<SelectedTopFilters>
  >
}) {
  const allFilters = Array.from([
    ...selectedTopFilters.amenities,
    ...selectedTopFilters.neighborhoods,
    ...selectedTopFilters.hotelStarLevel,
    ...selectedTopFilters.guestRating,
    ...selectedTopFilters.propertyTypes
  ])
  if (allFilters.length > 0) {
    fireFilterBarGA4Event()
    return (
      <Wrapper
        data-testid="selected-top-filter-bar"
        flexWrap={['nowrap', null, 'wrap']}
        mt={[0, null, 3]}
        mb={[3, null, 0]}
      >
        {allFilters.map(item => {
          const filterStringValues = item.split('_')
          const [filterType, code, name] = filterStringValues as [
            FilterCategoryHotel,
            string,
            string
          ]
          return (
            <FilterChip
              key={`filter-chip-${name}`}
              selected
              showActionIcon
              onClick={() => {
                setSelectedTopFilters((prev: SelectedTopFilters) => {
                  const newFilteredSet = new Set(prev[filterType])
                  const gaFilterItem =
                    filterType === 'neighborhoods' ? code : name
                  fireFilterBarChipGA4Event(filterType, gaFilterItem, false)
                  newFilteredSet.delete(`${filterType}_${code}_${name}`)
                  return {
                    ...prev,
                    [filterType]: newFilteredSet
                  }
                })
              }}
            >
              <Text textStyle="caption">{name}</Text>
            </FilterChip>
          )
        })}
      </Wrapper>
    )
  }
  return undefined
}
