import config from 'isomorphic-config'
import isAbortSignalTimeoutSupported from '@/shared-utils/is-abortSignal-supported'
import type { GraphResponse, GraphResponseData } from '@/types'
import getCookieByName from '@/shared-utils/get-cookie-by-name'
import { jsonContentTypeHeader } from '../server/constants'
import analytics from './analytics'
import getAuthorizationToken from './get-authorization-token'

type RecommendationsServiceSuccessfulResponse = {
  filterPreferencesByAuthToken: ReadonlyArray<{
    label?: string
    value?: string
  }>
}

function isValidGraphQLResponse(
  payload: unknown
): payload is GraphResponse<RecommendationsServiceSuccessfulResponse> {
  if (payload && payload instanceof Object) {
    if ('errors' in payload && Array.isArray(payload.errors)) {
      return true
    }

    if (
      'data' in payload &&
      payload.data &&
      typeof payload.data === 'object' &&
      'filterPreferencesByAuthToken' in payload.data
    ) {
      const { filterPreferencesByAuthToken } = payload.data
      return Array.isArray(filterPreferencesByAuthToken)
    }
  }
  return false
}

function isRecommendedCityIdsResult(
  payload: GraphResponse<RecommendationsServiceSuccessfulResponse>
): payload is GraphResponseData<RecommendationsServiceSuccessfulResponse> {
  if (
    'data' in payload &&
    typeof payload.data === 'object' &&
    payload.data &&
    'filterPreferencesByAuthToken' in payload.data &&
    typeof payload.data.filterPreferencesByAuthToken === 'object' &&
    payload.data.filterPreferencesByAuthToken &&
    Array.isArray(payload.data.filterPreferencesByAuthToken)
  ) {
    return true
  }
  return false
}

export default async function fetchRecommendedCityIds(props: {
  appName: string
  clientGUID: string
  appVersion: string
}) {
  const authToken = getCookieByName('dmc') || ''
  const { appName, appVersion, clientGUID } = props
  const { url, timeout } = config.client['pcln-graph']
  const requestUrl = `${url}?gqlOp=fetchRecommendedCityIds`
  const signalOption = isAbortSignalTimeoutSupported()
    ? { signal: AbortSignal.timeout(timeout) }
    : {}
  const options = {
    ...signalOption,
    method: 'POST',
    headers: {
      ...jsonContentTypeHeader,
      'apollographql-client-name': appName,
      'apollographql-client-version': appVersion,
      Authorization: getAuthorizationToken()
    },
    body: JSON.stringify({
      query: `
          query fetchRecommendedCityIds($authToken: String!){
              filterPreferencesByAuthToken(
                  authToken: $authToken,
                  type: PROFILE_RECOMMENDATION_CITY_ID,
                  maxResults: 8
                  ) {
                  label
                  value
              }
          }`,
      variables: {
        authToken
      }
    })
  }
  try {
    const response = await fetch(requestUrl, options)
    const { status, statusText, type, url: urlValue } = response
    if (!response.ok) {
      const logObject = {
        response: {
          status,
          statusText,
          type,
          url: urlValue
        }
      }
      analytics.logError({
        message: `fetchRecommendedCityIds GraphQL response status is > 299.`,
        cguid: clientGUID,
        responseData: JSON.stringify(logObject)
      })
    } else {
      const responseData = await response.json()

      if (isValidGraphQLResponse(responseData)) {
        if (isRecommendedCityIdsResult(responseData)) {
          analytics.log({
            message: `fetchRecommendedCityIds GraphQL completed with successful response`
          })
          return responseData.data.filterPreferencesByAuthToken
        }

        analytics.logError({
          message: 'fetchRecommendedCityIds GraphQL response return errors',
          cguid: clientGUID,
          responseData: JSON.stringify(responseData)
        })
      } else {
        analytics.logError({
          message: `fetchRecommendedCityIds GraphQL response is not valid.`,
          cguid: clientGUID,
          responseData: JSON.stringify(responseData)
        })
      }
    }
  } catch (error) {
    analytics.logError({
      message: `fetchRecommendedCityIds GraphQL request failed.`,
      cguid: clientGUID,
      responseError: JSON.stringify(error)
    })
  }
  return []
}
