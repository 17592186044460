import { update } from 'ramda'
import type { LOCATION_SEARCH_TYPE } from '@/types'
import { multiHotelSearch } from '@/shared-utils/packages-search'
import type { MultiHotelFormState, HotelDetail } from './types'
import {
  initialState as singleHotelFormInitialState,
  formatHotelsURL
} from '../utils'

const HOTEL_IDS = [0, 1, 2, 3, 4] as const

const defaultTravelers = {
  adults: 2,
  children: 0,
  childrenAges: {},
  rooms: 1
} as const

export const fields = ['hotels', 'travelers'] as const

function getFirstUnusedId(hotels: readonly HotelDetail[]) {
  const unusedIds = HOTEL_IDS.filter(id => !hotels.find(h => h.id === id))
  return unusedIds[0]
}

export function getInitialState(
  startDate: string,
  endDate: string,
  endLocation: LOCATION_SEARCH_TYPE | null
) {
  return {
    hotels: [
      {
        endLocation,
        startDate,
        endDate,
        id: 0
      },
      {
        startDate: '',
        endDate: '',
        endLocation: null,
        id: 1
      }
    ],
    travelers: defaultTravelers
  }
}

export function setHotels(
  hotels: readonly HotelDetail[],
  data: Partial<HotelDetail>,
  index: number
) {
  const hotelToAdd = { ...hotels[index], ...data }
  return update(index, hotelToAdd, hotels)
}

export function addDestination(hotels: readonly HotelDetail[]) {
  if (hotels.length > 4) return null
  const hotelToAdd = {
    endLocation: null,
    startDate: hotels.at(-1)?.endDate ?? '',
    endDate: '',
    id: getFirstUnusedId(hotels)
  }
  return [...hotels, hotelToAdd]
}

export function removeDestination(
  hotels: readonly HotelDetail[],
  index: number
) {
  return hotels.filter((_, hotelIndex) => hotelIndex !== index)
}

export function handleSubmitForm(data: MultiHotelFormState) {
  const { hotels, travelers } = data
  if (hotels.length === 0) return

  if (hotels.length === 1) {
    const url = formatHotelsURL({
      travelers,
      startDate: hotels[0].startDate,
      endDate: hotels[0].endDate,
      endLocation: hotels[0].endLocation,
      startLocation: null,
      tripType: 'STAY',
      mapView: false,
      selectedTopFilters: {
        ...singleHotelFormInitialState.selectedTopFilters
      }
    })
    if (url) {
      window.open(url)
      return
    }
  }
  return multiHotelSearch(data)
}
