import { useEffect, useState } from 'react'
import type { CityId } from '@/types'
import fetchRecommendedCityIds from '@/shared-utils/fetch-recommended-city-ids'
import useBootstrapData from './useBootstrapData'

function isValidCityId(data: Partial<CityId>): data is CityId {
  return typeof data.label === 'string'
}

let cacheRecommendedCityIds: ReadonlyArray<CityId> = []
const useRecommendedCityIds = ({
  shouldFetchRecommendedCityIds
}: {
  shouldFetchRecommendedCityIds: boolean
}) => {
  const {
    appName,
    appVersion,
    webstats: { clientGUID }
  } = useBootstrapData()
  const [recommendedCityIds, setRecommendedCityIds] = useState<
    ReadonlyArray<CityId>
  >([])

  useEffect(() => {
    const getRecommendations = async () => {
      if (cacheRecommendedCityIds.length > 0) {
        setRecommendedCityIds(cacheRecommendedCityIds)
        return
      }

      const result = await fetchRecommendedCityIds({
        appName,
        appVersion,
        clientGUID
      })

      setRecommendedCityIds(() => {
        cacheRecommendedCityIds = result.filter(isValidCityId)
        return cacheRecommendedCityIds
      })
    }
    if (shouldFetchRecommendedCityIds) {
      void getRecommendations()
    }
  }, [appName, appVersion, clientGUID, shouldFetchRecommendedCityIds])

  return { recommendedCityIds }
}

export default useRecommendedCityIds
