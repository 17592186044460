import React, {
  type SyntheticEvent,
  useContext,
  useEffect,
  useState
} from 'react'
import { Box, Flex, Text, Button, lineHeights } from 'pcln-design-system'
import { Calendar } from '@pcln/date-picker'
import { Controller, useForm } from 'react-hook-form'
import { getHotelDateRange } from '@/shared-utils/date-helper'
import SearchFormButton from '@/components/SearchFormButton'
import TypeAhead from '@/components/TypeAhead'
import { PopoverContainer } from '@/components/Popover'
import ShadowEffect from '@/components/ShadowEffect'
import CalendarWrapperBox from '@/components/CalenderWrapperBox.styled'
import TravelerSelection, {
  type ITravelerSelectionState
} from '@pcln/traveler-selection'
import DateField from '@/components/DateField'
import useTabGroup from '@/hooks/useTabGroup'
import type { LOCATION_SEARCH_TYPE } from '@/types'
import { yupResolver } from '@hookform/resolvers/yup'
import useBootstrapData from '@/hooks/useBootstrapData'
import useIsIPad from '@/hooks/useIsIPad'
import { SearchFormContext } from '@/context/SearchFormContext'
import logSearchFormAnalytics from '@/shared-utils/log-search-form-analytics'
import { initialState as singleHotelFormInitialState } from '../utils'
import { CALENDAR_POPOVER_SELECTOR, VP_DISCLAIMER } from '../../constants'
import {
  fields,
  getInitialState,
  setHotels,
  addDestination,
  removeDestination,
  handleSubmitForm
} from './utils'
import { searchFormSchema } from './validation'
import type { MultiHotelFormState } from './types'

type MultiHotelFormType = {
  endLocation: LOCATION_SEARCH_TYPE | null
  startDate: string
  endDate: string
}

function MultiHotelForm({
  startDate,
  endDate,
  endLocation: initialEndLocation
}: Readonly<MultiHotelFormType>) {
  const [isHotelCalendarOpen, setIsHotelCalendarOpen] = useState([
    false,
    false,
    false,
    false,
    false
  ])
  const { registerTabElement } = useTabGroup()
  const {
    webstats: { clientGUID: cguid }
  } = useBootstrapData()
  const isIPad = useIsIPad()

  const initialValues = getInitialState(startDate, endDate, initialEndLocation)

  const {
    clearErrors,
    register,
    unregister,
    setValue,
    handleSubmit,
    watch,
    trigger,
    control,
    formState: { errors }
  } = useForm<MultiHotelFormState>({
    defaultValues: initialValues,
    mode: 'onSubmit',
    resolver: yupResolver(searchFormSchema),
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    fields.forEach(name => {
      register(name)
    })
    return () => {
      fields.forEach(name => {
        unregister(name)
      })
    }
  }, [register, unregister])
  const { updateFormValues } = useContext(SearchFormContext)

  const { hotels: watchHotels, travelers: watchTravelers } = watch()

  const maxDaysToSelect = 30
  const inputFormMargin = 12

  useEffect(() => {
    void trigger('travelers.children')
  }, [trigger, watchTravelers])

  useEffect(() => {
    if (updateFormValues) {
      if (watchHotels.length > 0) {
        const { startDate: start, endDate: end, endLocation } = watchHotels[0]
        updateFormValues({
          tripType: 'STAY',
          startLocation: null,
          endLocation,
          startDate: start,
          endDate: end,
          travelers: watchTravelers,
          mapView: false,
          selectedTopFilters: {
            ...singleHotelFormInitialState.selectedTopFilters
          }
        })
      }
    }
  }, [updateFormValues, watchHotels, watchTravelers])

  function closeAllCalendars() {
    setIsHotelCalendarOpen(isHotelCalendarOpen.map(() => false))
  }

  function openCalendarAtIndex(index: number) {
    setIsHotelCalendarOpen(isHotelCalendarOpen.map((_, i) => index === i))
  }

  return (
    <form
      data-testid="multi-hotel-form"
      aria-label="hotel-search-form"
      onSubmit={handleSubmit((data: MultiHotelFormState) => {
        handleSubmitForm(data)
        logSearchFormAnalytics(cguid, 'landing', 'multi-hotel', false)
      })}
    >
      <Flex flexWrap="wrap">
        {watchHotels?.map((hotel, index) => {
          return (
            <React.Fragment key={hotel.id}>
              <Flex
                width={1}
                mb={12}
                alignItems="center"
                style={{ columnGap: '12px' }}
              >
                <Text pl={2} fontSize={14} bold>
                  Hotel {index + 1}
                </Text>
                {index > 0 && (
                  <Button
                    color="primary"
                    p={0}
                    variation="link"
                    onClick={(e: SyntheticEvent) => {
                      e.preventDefault()
                      const result = removeDestination(watchHotels, index)
                      setValue('hotels', result, { shouldDirty: true })
                      clearErrors(`hotels.${index}`)
                    }}
                  >
                    Remove
                  </Button>
                )}
              </Flex>
              <Flex flexDirection="row" flexWrap="wrap" width={1}>
                <Box width={[1, null, 1 / 2]} mb={inputFormMargin} px={2}>
                  <ShadowEffect>
                    {({
                      disableShadowState
                    }: {
                      disableShadowState: () => void
                    }) => (
                      <Box textAlign="left">
                        <TypeAhead
                          isTwoLineDisplay
                          searchProduct="hotels"
                          label="Where to?"
                          placeholder="Where to?"
                          searchKey={`hotels.${index}.endLocation`}
                          errors={errors}
                          disableShadowState={disableShadowState}
                          defaultSelectedItem={hotel.endLocation}
                          ref={(elem: HTMLInputElement) => {
                            registerTabElement(elem)
                          }}
                          showCurrentLocation={false}
                          onItemSelect={(item: LOCATION_SEARCH_TYPE) => {
                            const result = setHotels(
                              watchHotels,
                              {
                                endLocation: item
                              },
                              index
                            )
                            setValue('hotels', result, { shouldDirty: true })
                            if (errors?.hotels?.[index]?.endLocation) {
                              void trigger(`hotels.${index}.endLocation`)
                            }
                          }}
                        />
                      </Box>
                    )}
                  </ShadowEffect>
                </Box>
                <Box
                  width={[1, null, 1 / 2]}
                  mb={inputFormMargin}
                  px={2}
                  textAlign="left"
                >
                  <ShadowEffect>
                    {({
                      disableShadowState
                    }: {
                      disableShadowState: () => void
                    }) => (
                      <PopoverContainer
                        open={isHotelCalendarOpen[index]}
                        data-calendar
                        onDismiss={() => {
                          closeAllCalendars()
                          disableShadowState()
                        }}
                        domSelectorForVisuallyContainedElement={
                          CALENDAR_POPOVER_SELECTOR
                        }
                      >
                        <DateField
                          index={index}
                          data-testid={`hotel-date-range-${index}`}
                          m={0}
                          ref={registerTabElement}
                          aria-expanded={
                            isHotelCalendarOpen[index] ? 'true' : 'false'
                          }
                          error={
                            errors?.hotels?.[index]?.startDate?.message ??
                            errors?.hotels?.[index]?.endDate?.message
                          }
                          label="Check-in - Check-out"
                          name="hotelDates"
                          onFocus={() => {
                            openCalendarAtIndex(index)
                          }}
                          value={getHotelDateRange({
                            startDate: hotel.startDate,
                            endDate: hotel.endDate
                          })}
                        />
                        <CalendarWrapperBox
                          boxShadowSize="overlay-lg"
                          borderRadius="2xl"
                        >
                          <Calendar
                            use2024DesignSeti
                            isMobile={false}
                            roundedCorners
                            maxDaysToSelect={maxDaysToSelect}
                            monthWidthPx={350}
                            startDate={hotel.startDate}
                            endDate={hotel.endDate}
                            onChange={(
                              _,
                              { startDate: start, endDate: end }
                            ) => {
                              if (start && end) {
                                closeAllCalendars()
                                disableShadowState()
                              }
                              const dateObj = { startDate: start, endDate: end }
                              const result = setHotels(
                                watchHotels,
                                dateObj,
                                index
                              )
                              setValue('hotels', result, { shouldDirty: true })
                              if (start)
                                void trigger(`hotels.${index}.startDate`)
                              if (end) void trigger(`hotels.${index}.endDate`)
                            }}
                            monthsToShow={2}
                            onDismiss={() => {
                              closeAllCalendars()
                              disableShadowState()
                            }}
                            useDefaultFooter
                          />
                        </CalendarWrapperBox>
                      </PopoverContainer>
                    )}
                  </ShadowEffect>
                </Box>
              </Flex>
            </React.Fragment>
          )
        })}
        <Flex width={1} alignItems="center" mb={12}>
          <Button
            color="primary"
            mx={2}
            py={10}
            px={12}
            variation="subtle"
            disabled={watchHotels?.length > 4}
            onClick={(e: SyntheticEvent) => {
              e.preventDefault()
              const result = addDestination(watchHotels)
              if (result) {
                setValue('hotels', result, { shouldDirty: true })
              }
            }}
          >
            <Text lineHeight={lineHeights.standard}>Add Another Hotel</Text>
          </Button>
        </Flex>
        <Box width={[1, null, 1 / 2]} mb={3} px={2} tabIndex={0}>
          <Controller
            control={control}
            name="travelers"
            defaultValue={watchTravelers}
            render={({ field }) => (
              <TravelerSelection
                {...field}
                initialValues={field.value}
                trapFocus={!isIPad}
                isMobile={false}
                hasShadowEffect
                height={56}
                errorHeight={28}
                borderRadius="lg"
                onChange={(updatedValues: ITravelerSelectionState) => {
                  field.onChange({
                    childrenAges: updatedValues.childrenAges,
                    children: updatedValues.children,
                    rooms: updatedValues.rooms,
                    adults: updatedValues.adults
                  })
                }}
                roomsEnabled
                disclaimer={VP_DISCLAIMER}
                errorMessage={
                  errors?.travelers?.children?.message ??
                  errors?.travelers?.rooms?.message ??
                  ''
                }
                maxTravelers={8}
                ageSelectEnabled
                lapInfantLabel={false}
                tooltipErrorMessageId="travelerSelectionError"
                isHotel
              />
            )}
          />
        </Box>
        <Box
          width={[1, null, 1 / 2]}
          px={2}
          mb={1}
          ml="center"
          style={{
            overflowY: 'visible'
          }}
        >
          <SearchFormButton
            buttonText="Find Your Hotels"
            data-testid="HOTELS_SUBMIT_BUTTON"
          />
          <Box pt={2} textAlign="center">
            <Text fontSize={0}>
              Add up to 5 hotels and maximize your savings!
            </Text>
          </Box>
        </Box>
      </Flex>
    </form>
  )
}

export default MultiHotelForm
