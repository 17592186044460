export const HotelOption = {
  name: 'hotel',
  type: 'STAY',
  label: 'Add a hotel',
  id: 'add-a-hotel-checkbox'
} as const

export const FlightOption = {
  name: 'flight',
  type: 'FLY',
  label: 'Add a flight',
  id: 'add-a-flight-checkbox'
} as const

export const CarOption = {
  name: 'car',
  type: 'DRIVE',
  label: 'Add a car',
  id: 'add-a-car-checkbox'
} as const

const typeMap: { [key: string]: string } = {
  STAY: 'h',
  FLY: 'a',
  DRIVE: 'c'
}
const validCombinations = new Set([
  'hh',
  'hhac',
  'hhc',
  'hha',
  'hac',
  'hc',
  'ac',
  'ha'
])

export function GA4TripTypeMapper(tripType: string) {
  const modes = tripType.split(':').map(mode => typeMap[mode])
  const sortedModes = modes.sort((a, b) => {
    const order = ['h', 'a', 'c']
    return order.indexOf(a) - order.indexOf(b)
  })
  const mappedTripType = sortedModes.join('')
  if (!validCombinations.has(mappedTripType)) {
    throw new Error('Invalid trip type combination')
  }

  return mappedTripType
}
